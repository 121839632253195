import { NgModule } from '@angular/core';
import { FilterByPipe } from '../../pipe/filter-by.pipe';


@NgModule({
  imports: [
  ],
  declarations: [FilterByPipe],
  exports:[
    FilterByPipe
  ]
})
export class FilterByPipeModule { }
