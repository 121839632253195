import { Pipe, PipeTransform } from '@angular/core';
/*
 * Filters an arrayRaise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | filterBy:exponent
 * Example:
 *   {{ 2 | filterBy:10 }}
 *   formats to: 1024
*/
@Pipe({
    name: 'filterBy',
    pure: false
})
export class FilterByPipe implements PipeTransform {
    transform(items: any[], field : string, value : any): any[] {  
        if (!items) return [];
        if (!value || value.length == 0) return items;

        return items.filter(item => item[field] === value);
    }
}